// FONT-SIZES
$fs_main_title   : 28px;
$fs_second_title : 20px;
$fs_third_title  : 18px;
$fs_body         : 16px;
$fs_paginate     : 13px;

// FONT-WEIGHT
$fw_main_title   : 700;
$fw_second_title : 700;
$fw_third_title  : 700;
$fw_body         : 500;
$fw_paginate     : 500;


@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.drawer-container{
    display         : flex;
    align-items     : center;
    flex-direction  : column;
    padding         : 30px;
    background      : $blue;
    overflow-y      : auto;
    // min-height      : 730px;
    // min-height: -webkit-fill-available;
    // height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    // height: calc(var(--vh, 1vh) * 100);

    .drawer-logo{
        margin : 50px 0 20px 0;
        width  : 243px;
    }

    .drawer-language{
        width         : 80%;
        margin        : auto;
        margin-top    : 10px;
        margin-bottom : 50px;
    }

    .drawer-button{
        display         : flex;
        justify-content : center;
        margin          : 10px;
  
        .drawer-button-button {
            width          : 202px;
            height         : 50px;
            box-shadow     : 0 0 30px;
            font-size      : 18px;
            font-weight    : 700;
            padding-top    : 10px;
            padding-bottom : 10px;
            border         : none;
            border-radius  : 22px;
            color          : $white;
            background     : $darkblue;
            box-shadow     : 0 0 30px rgba(0, 0, 0, 0.1);
            cursor         : pointer;
        }
    }

    .deconnexion-button {
        position      : absolute;
        display       : flex;
        cursor        : pointer;
        // margin-top    : 230px;
        border-radius : 50px;
        width         : 160px;
        color         : $darkblue;
        background    : $darkgrey;
        bottom        : 30px;
        
        .deconnexion-button-icon{
            padding       : 10px;
            border-radius : 50px;
            background    : $white;
            border : 1px solid $grey;
        }
        .deconnexion-button-text{
            display     : flex;
            align-items : center;
            margin-left : 10px;
            font-size   : $fs_body;
            font-weight : $fw_body;
        }
    }
}  
@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.config-content{
    padding : 50px;
    flex    : 1; 
    
    .config-container{
        display        : flex;
        flex-direction : column;
        height         : 100%;

        .config-title{
            .config-title-text{
                width       : 100%;
                font-size   : $fs_main_title ;
                font-weight : $fw_main_title ;
                color       : $darkblue;
            }
        }
    
        /* Top div */
        .config-top-container{
            display         : flex;
            justify-content : space-between;
            align-content   : center;
            align-items     : center;
            flex-direction  : row;

            /* Button 'Créer produit' */
            .config-buttons{
                display        : flex;
                flex-direction : row;
            }
        }
    
        /* Roles items */
        .config-roles{
            display         : flex;
            flex-direction  : row;
            justify-content : space-evenly;
            align-items     : center;
            margin          : 50px;
            color           : $darkblue;
            
            .config-roles-item{
                display        : flex;
                flex-direction : row;
                font-size      : $fs_body;
                font-weight    : $fw_body;
                background     : $lightgrey;
                
                .config-roles-item-title{
                    display     : flex;
                    align-items : center;
                    padding     : 0 15px 0 15px;
                }

                .config-roles-item-delete{
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    width           : 40px;
                    height          : 40px;
                    cursor          : pointer;
                    color           : $white;
                    background      : $darkblue;

                    :hover{
                        background : $darkblue;
                        color      : $lightgrey;
                        cursor     : pointer;
                    }
                }
            }
            :hover{
                background : $blue;
                color      : $lightgrey;
                cursor     : pointer;
            }
        }
    }
}
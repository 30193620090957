@import "./assets/Colors.scss"; 

@font-face {
  font-family: "icomoon";
  src: url("./assets/fontIcoMoon/fonts/icomoon.eot");
  src: url("./assets/fontIcoMoon/fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("./assets/fontIcoMoon/fonts/icomoon.woff")
      format("woff"), url("./assets/fontIcoMoon/fonts/icomoon.ttf") format("truetype"), url("./assets/fontIcoMoon/fonts/icomoon.svg#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family  : 'SF-Pro-Display';
  font-weight  : 700;
  font-display : auto;
  src: url('./assets/fonts/SF-Pro-Display-Bold.otf') format('truetype');
}
@font-face {
  font-family  : 'SF-Pro-Display';
  font-weight  : 500;
  font-display : auto;
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf') format('truetype');
}
@font-face {
  font-family  : 'SF-Pro-Display';
  font-weight  : 400;
  font-display : auto;
  src: url('./assets/fonts/SF-Pro-Display-Light.otf') format('truetype');
}

html, body {
  height: 100%;
}

body, input, textarea, button {
  margin      : 0 auto;
  font-family : 'SF-Pro-Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}
textarea {resize: none}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.param-carac-content{
    flex    : 1;
    padding : 50px;

    .param-carac-container{
        height : 95%;
        
        /* Top div */
        .param-carac-top-container{
            display         : flex;
            justify-content : space-between;
            align-content   : center;
            align-items     : center;
            flex-direction  : row;
            width           : 100%;
    
            /* Title */ 
            .param-carac-title{
                .param-carac-title-text{
                    width       : 100%;
                    font-size   : $fs_main_title;
                    font-weight : $fw_main_title;
                    color       : $darkblue;
                }
            }
        
            /* Button 'Ajouter une caractéristique' */
            .param-carac-buttons{
                display        : flex;
                flex-direction : row;
            }
        }
    }
}
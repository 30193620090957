@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.pagination{
    display         : flex;
    flex-direction  : row;
    justify-content : center;
    // position: absolute;
    margin          : 40px;

    .paginations-buttons-bloc{
        display        : flex;
        flex-direction : row;
        margin         : 0 25px;
    }
    .paginations-buttons-numbers{
        display       : flex;
        border        : 1px solid $grey;
    }
    .pagination-button{
        display         : flex;
        justify-content : center;
        padding         : 10px 15px;
        color           : $blue;
        cursor          : pointer;
        font-size       : $fs_body;
        font-weight     : $fw_body;

        &.disabled{
            cursor : no-drop;
            color  : grey;
        }
        &.active{
            cursor        : default;
            color         : $white;
            background    : $blue;
            border        : 1px solid $blue;
        }
    }
}


@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.produits-content{
    display        : flex;
    flex-direction : row;
    flex           : 1;
    overflow-y     : auto;
    box-sizing : border-box;


    /* Panel */
    .produits-panel{
        position   : absolute;
        left       : 300px;
        top        : 0;
        width      : 449px;
        height     : 100vh;
        min-height : 810px;
        background : $grey;
        padding    : -50px;

        .produits-panel-filtres{
            display      : flex;
            align-items  : center;
            color        : $darkblue;
            cursor       : pointer;
            margin-top   : 99px;
            margin-left  : 50px;
            padding      : 0;
            
            .produits-panel-filtres-text{
                font-size : $fs_second_title;
            }
        }

        .produits-panel-body{
            display        : flex;
            align-items    : center;
            flex-direction : column;
            margin-top     : 20px;

            .produits-panel-dropdown{
                margin-top : 20px;
    
                .produits-panel-dropdown-label{
                    font-size     : 16px;
                    font-weight   : 700;
                    margin-bottom : 5px;
                    color         : $darkblue;
                }
                .produits-panel-dropdown-input{
                    width         : 296px;
                    height        : 45px;
                    box-shadow    : 0 0 10px;
                    background    : $white;
                    border-radius : 5px;
                }
            }

            .selected-items-block{
                display         : flex;
                justify-content : center;
                max-width       : 300px;
                margin-top      : 10px;
    
                .selected-items-item{
                    display         : flex;
                    justify-content : space-between;
                    align-items     : center;
                    border-radius   : 30px;
                    font-size       : 14px;
                    margin          : 5px;
                    padding         : 5px 10px;
                    color           : $darkblue;
                    background      : $white;
                }
            }
        }
    }

    /* Container */
    .produits-container{
        display        : flex;
        flex-direction : column;
        // width          : 100%;
        // height         : 100%;
        flex       : 1;
        margin     : 50px !important;
        box-sizing : border-box;

        .produits-top-container{
            display         : flex;
            justify-content : space-between;
            align-content   : center;
            align-items     : center;
            flex-direction  : row;
            // margin-top     : 50px;

            .produits-buttons{
                display        : flex;
                flex-direction : row;
            }
        
            .produits-top-row{
                display         : flex;
                align-items     : baseline;
                flex-direction  : column;
                margin-bottom   : 15px;
                color           : $darkblue;
                
                .produits-top-row-title{
                    font-size     : $fs_main_title;
                    font-weight   : $fw_main_title;
                }
                
                .produits-top-row-results{
                    font-size   : $fs_second_title;
                    font-weight : 300;
                    margin-top  : 5px;
                }
            }
        
            /* Button 'Filtres' */
            .produits-top-filtres{
                display     : flex;
                align-items : center;
                color       : $darkblue;
                padding     : 0;
                cursor      : pointer;
                
                .produits-top-filtres-text{
                    font-size : 20px;
                }
            }
        }

        .produits-switchButtons{
            display         : flex;
            justify-content : center;
            align-items     : center;
            width           : 100%;
            height          : 45px;
            margin          : 50px 0;
            
            .produits-switchButtons-switch{
                display         : flex;
                justify-content : center;
                align-items     : center;
                height          : 100%;
                font-size       : $fs_second_title;
                font-weight     : $fw_second_title;
                cursor          : pointer;
                padding : 5px 20px;

                &.left{
                    border-top-left-radius    : 30px;
                    border-bottom-left-radius : 30px;
                }
                &.right{
                    border-top-right-radius    : 30px;
                    border-bottom-right-radius : 30px;
                }
            }
        }
    }
}
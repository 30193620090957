@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.projets-container{
    padding : 50px;
    flex    : 1; 

    .projets-title{
        
        .projets-title-text{
            width       : 100%;
            color       : $darkblue;
            font-weight : $fw_main_title;
            font-size   : $fs_main_title;
        }
    }

    /* Top div */
    .projets-top-container{
        display         : flex;
        justify-content : space-between;
        align-content   : center;
        align-items     : center;
        flex-direction  : row;

        /* Button 'Créer produit' */
        .projets-buttons{
            display        : flex;
            flex-direction : row;
        }
    }
}
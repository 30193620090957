@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.progression-container{
    padding : 50px;
    flex    : 1; 

    .progression-body-row{
        display        : flex;
        flex-direction : column;
        padding        : 15px;
        padding-left   : 40px;
        border-radius  : 5px;
        margin-top     : 27px;
        color          : $darkblue;
        background     : $grey;
        font-size      : $fs_second_title;
        font-weight    : $fw_second_title;

        .progression-body-row-title{
            font-size : $fs_third_title;
            margin    : 10px 0 20px 0;
        }

        .line{
            background : $darkblue;
            height     : 1px;
            width      : 90%;
        }

        .progression-body-row-text{
            display         : flex;
            flex-direction  : row;
            justify-content : space-between;
            width           : 90%;
            margin          : 10px 0 10px 0;
            font-size       : $fs_body;
            font-weight     : $fw_body;
        }
    }
}
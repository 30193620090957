@import "../../assets/Colors.scss"; 

.login-container{
  display          : flex;
  width            : 100%;
  height           : 100vh;
  justify-content  : center;
  align-items      : center;
  background-image : linear-gradient(180deg, #0599ff 0%, #034d80 100%);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  .panel-row{
    display         : flex;
    flex-direction  : column;
    justify-content : space-evenly;
    align-items     : center;
    width           : 760px;
    padding         : 30px;
    border-radius   : 20px;
    background      : $grey;
    color           : $darkblue;

    .panel-logo{
      margin : 0 auto;
      width  : 200px;
    }

    .panel-title{
      display         : flex;
      justify-content : center;
      align-items     : center;
      font-size       : 22px;
      
      .panel-title-text{
        font-weight : bolder;
      }
    }

    .panel-inputs{
      width : 80%;

      .panel-input{
        margin-bottom  : 20px;
  
        .panel-input-text{
          display         : flex;
          justify-content : left;
          margin          : 0 0 5px 0;
          font-size       : 18px;
        }
        .panel-input-input{
          display       : flex;
          height        : 50px;
          width         : 100%;
          border        : none;
          padding       : 0 15px 0 15px;
          margin-left   : -15px;
          border-radius : 20px;
          font-size     : 16px;
        }
      }
    }

    .panel-errorMessage{
      display         : flex;
      justify-content : center;
      align-items     : center;
      font-size       : 18px;
      margin-bottom   : 15px;
    }

    .panel-button{
      .panel-button-button{
        width         : 232px;
        height        : 50px;
        font-weight   : bolder;
        padding       : 12px 40px 12px 40px;
        border        : none;
        border-radius : 50px;
        color         : $white;
        background    : $darkblue;
        cursor        : pointer;
        font-size     : 16px;
      }
    }
  }
}
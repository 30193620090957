@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.alertMessage-body{
    position : absolute;
    width    : 100% !important;
    height   : 100% !important;
    left     : 0;
    right    : 0;
    top      : 0;
    flex     : 1;
    margin   : 0 auto;

    .alertMessage-background{
        position   : absolute;
        width      : 100% !important;
        height     : 100vh !important;
        flex       : 1;
        background : $black;
        opacity    : 0.5;
    }
    .alertMessage-content{
        position        : absolute;
        display         : flex;
        justify-content : space-evenly;
        align-items     : center;
        flex-direction  : column;
        padding         : 30px;
        border-radius   : 20px;
        left            : 50%;
        top             : 50%;
        transform       : translate(-50%,-50%);
        max-width       : 530px;
        background      : $white;
        
        .alertMessage-title{
            text-align  : center;
            font-size   : $fs_second_title;
            font-weight : $fw_second_title;
            margin      : 50px 0 50px 0;
            color       : $blue;
        }
    
        .alertMessage-block-buttons{
            display         : flex;
            justify-content : center;
    
            .alertMessage-block-buttons-button{
                padding       : 0 20px 0 20px;
                margin        : 0 10px 0 10px;
                height        : 55px;
                font-size     : $fs_third_title;
                font-weight   : $fw_third_title;
                border-radius : 20px;
                box-shadow    : 0 5px 15px rgba(5, 153, 255, 0.16);
                cursor        : pointer;
                border        : none;
                
                &.blue{
                    color      : $white;
                    background : $blue;
                }
                &.white{
                    color      : $blue;
                    background : $white;
                }
            }
        }
    }
}
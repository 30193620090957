@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.paramCat-container{
    flex       : 1;
    height     : 100%;
    padding    : 0 50px 0 50px;
    overflow-y : auto;
    color      : $darkblue;

    .paramCat-title{
        .paramCat-title-text{
            margin-top  : 50px;
            width       : 100%;
            font-size   : $fs_main_title;
            font-weight : $fw_main_title;
            color       : $darkblue;
        }
    }

    /* Catalogue */
    .paramCat-firstCat{
        display         : flex;
        flex            : 1;
        align-items     : flex-end;
        justify-content : space-between;
        flex-direction  : row;
        padding         : 10px;
        font-size       : $fs_second_title;
        font-weight     : $fw_second_title;
        
        .paramCat-firstCat-title{
            display        : flex;
            flex-direction : row;
            align-items    : center;
        }
    }

    .paramCat-content{
        padding : 30px 10px 50px 10px;

        /* Category */
        .paramCat-buttons{
            display        : flex;
            flex-direction : row;
        }
    
        /* Sub-Category */
        .paramCat-categories{
            background: $grey;
            // border-bottom : 1px solid $white;

            .paramCat-category{
                display         : flex;
                align-items     : center;
                justify-content : space-between;
                flex-direction  : row;
                padding         : 0 10px;
                margin-left     : -40px;
                font-size       : $fs_body;
                font-weight     : $fw_body;
                background      : $grey;
                border-bottom   : 1px solid $white;
        
                .paramCat-category-title{
                    display        : flex;
                    flex-direction : row;
                    align-items    : center;
                    margin-left    : 20px;
                    
                    .paramCat-category-title-text{
                        margin-left  : 10px;
                        margin-right : 90px;
                    }
                }
            }
        }

    
        .paramCat-addItem{
            display         : flex;
            justify-content : space-between;
            align-items     : center;
            flex-direction  : row;
            padding         : 10px;
            font-size       : $fs_body;
            font-weight     : $fw_body;
            background      : $grey;     
            color           : $darkblue; 
            border-bottom   : 1px solid $white;
    
    
            .paramCat-addItem-head{
                display        : flex;
                flex-direction : row;
                &.subCategory{ margin-left : 65px;}
                &.category{margin-left : 30px;}
    
                .input-add{
                    height        : 30px;
                    font-size     : $fs_body;
                    font-weight   : $fw_body;
                    border        : none;
                    margin        : 0;
                    padding       : 0;
                    margin-left   : 10px; 
                    background    : $grey;      
                    color         : $darkblue;  
                    border-bottom : 1px solid $darkblue;
                }
            }
        }
        .paramCat-add-button{
            display : flex;
            justify-content: flex-start;
    
            .button-add{
                display       : flex;
                margin        : 0;
                font-size     : $fs_body;
                font-weight   : $fw_body;
                border        : none;
                border-radius : 15px;
                cursor        : pointer;
                color         : $white;
                background    : $blue;
        
                &.subCat{
                    display     : flex;
                    align-items : flex-end;
                }
            }
        }
    }
}
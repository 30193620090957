@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.main-container{
    position       : relative;
    display        : flex;
    flex-direction : row;
    min-width      : 1645px;
    min-height     : 750px;
    // height         : 100%;
    // min-height: -webkit-fill-available;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    -webkit-touch-callout : none;
    -webkit-user-select   : none;
    -khtml-user-select    : none;
    -moz-user-select      : none;
    -ms-user-select       : none;
    user-select           : none;
}

/* Line */
.line{
    height     : 1px;
    background : $darkblue;

    .white{ background : $white; }
}

/* Buttons */
.delete-button{
    display         : flex;
    justify-content : center;
    align-items     : center;
    width           : 40px;
    cursor          : pointer;
    color           : $white;
    background      : $darkblue;
}

/* Checkboxes */
.checkbox{
    display         : flex;
    justify-content : center;
    align-content   : center;
    text-align      : center;
    width           : 17px;
    height          : 17px;
    background      : $white;
    border-radius   : 4px;
    margin          : 5px 0 5px 0;
    cursor          : pointer;
}
.checkbox-icon{
    width  : 15px;
    height : 17px;
}

/* Blue button */
.button-blue{
    display         : flex;
    align-items     : center;
    justify-content : space-evenly;
    margin          : 0 10px 0 10px;
    padding         : 0 25px 0 25px;
    font-size       : $fs_third_title;
    font-weight     : $fw_third_title;
    border          : none;
    border-radius   : 40px;
    height          : 62px;
    cursor          : pointer;
    color           : $white;
    background      : $blue;
    outline         : none !important;
}

/* Icons */
.icons-font{
    display   : flex;
    font-size : 30px;
    margin    : 0 10px;

    &.logout{
        font-size : 20px;
        margin : 0;
        color : $darkblue;
    }
    &.pointer{ cursor : pointer; }
    &.filtres{
        font-size : 15px;
    }
    &.search{
        font-size : 20px;
        width     : 20px;
        height    : 20px;
        margin    : 0;
        color     : $darkblue;
    }
    &.delete{
        font-size : 20px;
        cursor    : pointer;
    }
    &.help{
        font-size : 25px;
        display   : inline;
        position  : relative;
        cursor    : pointer;
    }    
    &.option{
        font-size : 20px;
        position  : absolute;
        right     : 65px;
        cursor    : pointer;
    }
    &.arrow{
        display   : flex;
        bottom    : 35%;
        font-size : $fs_body;
        position  : absolute;
        right     : 15px;
        margin    : 0;
    }
    &.add{
        display         : flex;
        justify-content : flex-start;
        color           : $white;
        margin-top      : 20px;
        font-size       : 40px;
        cursor          : pointer;
    }
    &.green{ color : green; }
    &.red{ color : $red; }
    &.selectable{
        cursor    : pointer;
        font-size : 18px;
        margin : 0 0 0 5px;
    }
    &.cat{
        font-size : 20px;
        cursor    : pointer;
    }
    &.subCat{
        cursor    : pointer;
        margin    : 10px;
        color     : $darkblue;
    }
    &.paginate{
        width     : 16px;
        height    : 16px;
        font-size : 16px;
    }
}


/* Table */
.table-header{
    display         : flex;
    justify-content : center;
    align-items     : center;
    font-size       : $fs_second_title;
    margin          : 30px 0 10px 0;

    &.removable{
        margin-right : 37px;
    }

    .subtitle{
        font-weight : 700;
        text-align  : center;
        color       : $darkblue;
    }
}
.table-items{
    overflow-y : auto;
    &.projects { height : 90%; margin-top: 20px; }
    &.produits { height : 90%; }
    &.attributs{ height : 90%; }
    &.config   { height : 90%; }
}

/* Searchbar */
.searchbar{
    display        : flex;
    flex-direction : row;
    margin         : 0 10px 0 10px;
    
    .searchbar-text{
        font-size                 : $fs_third_title;
        font-weight               : $fw_third_title;
        width                     : 325px;
        border                    : none;
        border-top-left-radius    : 40px;
        border-bottom-left-radius : 40px;
        padding                   : 21px;
        color                     : $darkblue;
        background                : $grey;
        outline                   : none !important;

    }
    .searchbar-icon{
        display                    : flex;
        align-items                : center;
        width                      : 30px;
        padding-right              : 15px;
        border-top-right-radius    : 40px;
        border-bottom-right-radius : 40px;
        background                 : $grey;
    }
}


/* Dropdowns */
.dropdown{
    position : relative;
    
    .dropbtn{
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        padding         : 20px;
        width           : 100%;
        min-width       : 160px;
        min-height      : 59px;
        font-size       : $fs_body;
        font-weight     : $fw_body;
        border-radius   : 8px;
        border          : none;
        cursor          : pointer;
        background      : $white;
        color           : $darkblue;
        margin-top      : 5px;
        box-shadow      : 0 5px 15px rgba(0, 0, 0, 0.16); 

        &.short{ 
            max-width  : 160px;
            max-height : 59px;
        }
        &.medium{ width : 276px; }
        &.produitAttr{ width : 380px; }

        .dropdown-name{
            padding-right  : 20px;
            text-overflow  : ellipsis;
            overflow       : hidden;
            white-space    : nowrap;
        }
    }
    .dropdown-content {
        z-index                    : 1;
        display                    : none;
        position                   : absolute;
        font-size                  : $fs_body;
        font-weight                : $fw_body;
        min-width                  : 160px;
        border-bottom-left-radius  : 8px;
        border-bottom-right-radius : 8px;
        box-shadow                 : 0px 16px 16px 0px rgba(0,0,0,0.2);
        background-color           : $white;
        width                      : 100%;
    }
    .dropdown-content div {
        display         : flex;
        align-items     : center;
        padding-left    : 20px;
        height          : 45px;
        font-size       : $fs_body;
        font-weight     : $fw_body;
        text-decoration : none;
        cursor          : pointer;
        color           : $darkblue;
        border-top      : 1px solid $grey;
    }
    .dropdown-content div:hover {
        background-color : #f1f1f1;
    }
    .dropdown-content div:active {
        .dropdown-content{ display : none; } 
    }
    .dropdown-content div:hover:last-child {
        border-bottom-left-radius  : 8px;
        border-bottom-right-radius : 8px;
    }
}

.dropdown:visible .dropbtn{
    display                 : flex;
    justify-content         : space-between;
    align-items             : center;
    border-radius           : 0;
    border-top-left-radius  : 8px;
    border-top-right-radius : 8px;
}
.dropdown .dropdown-content{
    display    : block;
    visibility : hidden;
}


/* Placeholder */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size   : 16px;
    font-weight : 400;
    color       : $darkblue;
}
::-moz-placeholder { /* Firefox 19+ */
    font-size   : 16px;
    font-weight : 400;
    color       : $darkblue;
}
:-ms-input-placeholder { /* IE 10+ */
    font-size   : 16px;
    font-weight : 400;
    color       : $darkblue;
}
:-moz-placeholder { /* Firefox 18- */
    font-size   : 16px;
    font-weight : 400;
    color       : $darkblue;
}

input[type=date] {
    min-width      : calc(236px);
    height         : 19px;
    font-size      : $fs_body;
    font-weight    : $fw_body;
    outline        : none;
    border         : 0;
    border-radius  : 8px;
    padding        : 20px;
    text-transform : uppercase;
    color          : $darkblue;
    box-shadow     : 0 5px 15px rgba(0, 0, 0, 0.16); 

    &::-webkit-clear-button {
        font-size    : 18px;
        height       : 30px;
        position     : relative;
        right        : 5px;
        margin-right : 4px;
    }
    &::-webkit-inner-spin-button {
        height : 30px;
    }
    &::-webkit-calendar-picker-indicator {
        font-size : 18px;
        &:hover {
            cursor: pointer;
        }
    }
}

input[type="text"]:disabled {
    background : $darkgrey !important;
    cursor     : not-allowed !important;
}
input[type="button"]:disabled {
    cursor : not-allowed !important;
}
button:disabled{
    background : $darkgrey !important;
    cursor     : not-allowed !important;
}
input[type="password"] {
    -webkit-text-security:disc;
    -mox-text-security:disc;
}
input[type="password"]:disabled {
    background : $darkgrey !important;
    cursor : not-allowed;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   -webkit-text-fill-color : $darkblue;
//   -webkit-box-shadow: 0 0 0px 1000px $white inset;
//   transition: background-color 5000s ease-in-out 0s;
// }

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?hkvq1c');
  src:  url('fonts/icomoon.eot?hkvq1c#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?hkvq1c') format('truetype'),
    url('fonts/icomoon.woff?hkvq1c') format('woff'),
    url('fonts/icomoon.svg?hkvq1c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\f002";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-power-off:before {
  content: "\f011";
}
.icon-download:before {
  content: "\f019";
}
.icon-tags:before {
  content: "\f02c";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-plus-circle:before {
  content: "\f055";
}
.icon-minus-circle:before {
  content: "\f056";
}
.icon-times-circle:before {
  content: "\f057";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-question-circle:before {
  content: "\f059";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-briefcase:before {
  content: "\f0b1";
}
.icon-list-ul:before {
  content: "\f0ca";
}
.icon-clipboard:before {
  content: "\f0ea";
}
.icon-paste:before {
  content: "\f0ea";
}
.icon-folder-o:before {
  content: "\f114";
}
.icon-folder-open-o:before {
  content: "\f115";
}
.icon-minus-square-o:before {
  content: "\f147";
}
.icon-long-arrow-left:before {
  content: "\f177";
}
.icon-long-arrow-right:before {
  content: "\f178";
}
.icon-cube:before {
  content: "\f1b2";
}
.icon-trash:before {
  content: "\f1f8";
}
.icon-user-plus:before {
  content: "\f234";
}
.icon-handshake-o:before {
  content: "\f2b5";
}

@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.lineItem-container{
    display    : flex;
    margin-top : 10px;

    .lineItem-item{
        display        : flex;
        align-items    : center;
        text-align     : center;
        flex-direction : row;
        padding        : 10px 0 10px 0;
        background     : $lightgrey;
        color          : $darkblue;
        width          : 100%;
        min-height     : 57px;
        margin         : 0 auto;

        .lineItem-item-cell{
            display         : flex;
            justify-content : center;
            font-size       : $fs_body;
            font-weight     : $fw_body;
            white-space     : nowrap;
            flex-wrap       : wrap;

            &.projets{
                padding : 0 24px 0 24px;
                cursor : pointer;
            }
        }
        &.editablePrj{
            display : flex;
            justify-content: space-between;
        }
        &.editablePrj:hover{
            background : $blue;
            color      : $lightgrey;
            cursor     : pointer;
        }
        &.editableProduit{
            padding : 2px 0 2px 0;
        }
        &.editableProduit:hover{
            background : $blue;
            color      : $lightgrey;
            cursor     : pointer;
        }
        &.editable:hover{
            background : $blue;
            color      : $lightgrey;
            cursor     : pointer;
        }
    } 
}
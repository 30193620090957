@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.param-content{
    display        : flex;
    flex-direction : row;
    flex           : 1;
    overflow-y     : auto;

    .param-panel{
        display        : flex;
        align-items    : center;
        text-align     : center;
        flex-direction : column;
        // width          : 300px;
        padding : 0 30px;
        height         : 100%;
        overflow-y     : auto;
        background     : $grey;

        .param-panel-title{
            width       : 100%;
            margin-top  : 70px;
            font-size   : $fs_second_title;
            font-weight : $fw_second_title;
            color       : $darkblue;
        }

        .param-panel-language{
            display        : flex;
            align-items    : flex-start;
            flex-direction : column;
            margin-top     : 30px;

            .param-panel-language-label{
                font-size   : $fs_third_title;
                font-weight : $fw_third_title;
                color       : $darkblue;
            }
        }

        .param-panel-buttons{
            display        : flex;
            flex-direction : column;
            margin-top     : 30px;
            background     : $veryDarkgrey;
            padding        : 20px;
            border-radius  : 15px;

            .param-panel-buttons-button{
                margin        : 7px 0 7px 0;
                font-size     : $fs_third_title;
                font-weight   : $fw_third_title;
                border        : none;
                border-radius : 22px;
                padding       : 0 15px;
                height        : 45px;
                cursor        : pointer;
                color         : $darkblue;
                background    : $lightgrey;
            }
        }
    }
}
@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.slidableMenu-content{
    position         : absolute;
    display          : flex;
    align-items      : center;
    flex-direction   : column;
    top              : 0;
    right            : 0;
    padding          : 0 50px 0 50px;
    // box-sizing       : border-box;
    background-image : linear-gradient(180deg, #0599ff 0%, #034d80 100%);
    overflow-y       : auto;
    overflow-x       : hidden;
    height : 100%;
    // min-height       : -webkit-fill-available;

    // height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    // height: calc(var(--vh, 1vh) * 100);

    .displayRow{
        display         : flex;
        flex-direction  : row; 
        justify-content : space-between;
        align-items     : center;
        color           : $white;
    }
    .displayColumn{
        display         : flex;
        flex-direction  : column; 
    }

    .slidableMenu-content-title{
        font-size   : 25px;
        font-weight : 700;
        color       : $white;
        margin-top  : 50px;
    }
    .linewhite{
        width         : 200px;
        height        : 10px;
        margin-top    : 10px;
        border-bottom : 1px solid $white;
    }

    .slidableMenu-content-block{
        display        : flex;
        flex-direction : column;
        width          : 100%;
      
        &.right{
            display        : flex;
            align-items    : flex-end;
            justify-content: center;
            flex-direction : column; 
        }

        .selected-items-block{
            display         : flex;
            justify-content : center;
            max-width       : 570px;
            margin-top      : 10px;

            .selected-items-item{
                display         : flex;
                justify-content : space-between;
                align-items     : center;
                border-radius   : 30px;
                font-size       : 14px;
                margin          : 5px;
                padding         : 5px 10px;
                color           : $darkblue;
                background      : $white;
            }
        }

        .slidableMenu-content-block-label{
            display     : flex;
            align-items : center;
            position    : relative; 
            font-size   : $fs_third_title;
            font-weight : $fw_third_title;
            margin-top  : 18px;
            color       : $white;
            
            &.center{
                text-align      : center;
                justify-content : center;
                align-self      : center;
                width           : 580px;
            }
            &.short{ margin-right : 130px; }
            &.shortEdit{ margin-right : 100px; }
        }
        .slidableMenu-content-block-flex{
            display        : flex;
            flex-direction : column;

            .slidableMenu-content-block-flex-block{
                display        : flex;
                flex-direction : row;
                align-items    : center;

                .slidableMenu-content-block-flex-block-input{
                    margin-top       : 5px;
                    // width            : 558px;
                    width            : 598px;
                    font-size        : $fs_body;
                    font-weight      : $fw_body;
                    padding          : 20px;
                    border-radius    : 8px;
                    border           : none;
                    padding-right    : 50px;
                    // text-overflow    : ellipsis;
                    box-sizing       : border-box;
                    background-color : $white;
                    box-shadow       : 0 5px 15px rgba(0, 0, 0, 0.16); 
                    color            : $darkblue;
                }
            }
        }

        .slidableMenu-content-block-label-counter{
            display         : flex;
            justify-content : flex-end;
            color           : $white;
            margin-top      : 5px;
        }
        // Simple input
        .slidableMenu-content-block-input-short{
            margin-top       : 5px;
            width            : 558px;
            font-size        : $fs_body;
            font-weight      : $fw_body;
            padding          : 20px;
            border-radius    : 8px;
            border           : none;
            background-color : $white;
            box-shadow       : 0 5px 15px rgba(0, 0, 0, 0.16); 
            color            : $darkblue;
        }
        // Textarea 
        .slidableMenu-content-block-input-long{
            margin-top       : 5px;
            width            : 558px;
            height           : 197px;
            font-size        : $fs_body;
            font-weight      : $fw_body;
            padding          : 20px;
            border-radius    : 8px;
            border           : none;
            background-color : $white;
            box-shadow       : 0 5px 15px rgba(0, 0, 0, 0.16); 
            color            : $darkblue;
        }

        .slidableMenu-content-block-checkboxBlock{
            display        : flex;
            align-items    : flex-start;
            flex-direction : column;
            margin         : 30px;

            .slidableMenu-content-block-checkboxRow{
                display         : flex;
                justify-content : center;
                align-items     : center;
                flex-direction  : row;
                margin-top      : 10px;

                -webkit-touch-callout: none; /* iOS Safari */
                  -webkit-user-select: none; /* Safari */
                   -khtml-user-select: none; /* Konqueror HTML */
                     -moz-user-select: none; /* Firefox */
                      -ms-user-select: none; /* Internet Explorer/Edge */
                          user-select: none; /* Non-prefixed version, currently
                                                supported by Chrome and Opera */

                &.second{
                    margin-left : 30px;
                }

                .slidableMenu-content-block-checkboxRow-checkbox{
                    padding    : 10px;
                    color      : green;
                    background : green;
                }
                .slidableMenu-content-block-checkboxRow-text{
                    font-size   : 18px;
                    font-weight : 700;
                    margin-left : 10px;
                    color       : $white;
                }
            }
        }

        .slidableMenu-content-block-buttons{
            display         : flex;
            justify-content : center;
            margin          : 50px 0 50px 0;

            .slidableMenu-content-block-buttons-button{
                padding       : 0 20px 0 20px;
                margin        : 0 10px 0 10px;
                height        : 55px;
                font-size     : $fs_third_title;
                font-weight   : $fw_third_title;
                border-radius : 20px;
                box-shadow    : 0 5px 15px rgba(5, 153, 255, 0.16);
                cursor        : pointer;

                &.white{
                    color      : $blue;
                    background : $white;
                    border     : none;
                }
                &.dark{
                    background : rgba(0, 0, 0, 0);
                    color      : $white;
                    border     : 1px solid $white;
                }
            }
        }
    }
}
$red          : #D40B0B;
$blue         : #0599ff;
$darkblue     : #263a7c;
$black        : #000000;
$veryDarkgrey : #d9d8d8;
$darkgrey     : #E1E1E1;
$grey         : #efefef;
$lightgrey    : #f9f9f9;
$white        : #ffffff;
$shadow       : rgba(5, 153, 255, 0.16);

:export {
    red          : $red;
    blue         : $blue;
    darkblue     : $darkblue;
    black        : $black;
    veryDarkgrey : $darkgrey;
    darkgrey     : $darkgrey;
    grey         : $grey;
    lightgrey    : $lightgrey;
    white        : $white;
    shadow       : $shadow;
}
@import "../../assets/Colors.scss"; 

.slidableMenu_projets_codeProjet{
    display          : flex;
    justify-content  : center;
    align-items      : center;
    padding          : 0 30px 0 30px;
    height           : 55px;
    border-radius    : 21px;
    font-size        : 22px;
    font-weight      : 700;
    margin-top       : 30px;
    color            : $darkblue;
    background-color : $white;
    cursor           : pointer;
}
@import "../../assets/Colors.scss"; 
@import "../../assets/Variables.scss"; 

.exports-container{
    flex            : 1; 
    display         : flex;
    justify-content : center;
    align-items     : center;
    color           : $darkblue;

    .exports-import-selector{
        position      : absolute;
        top           : 20px;
        left          : 350px;
        padding       : 20px;
        border-radius : 28px;
        background    : $grey;
    }

    .exports-box {
        display         : flex;
        justify-content : center;
        align-items     : center;
        flex-direction  : column;
        height          : 291px;
        border-radius   : 28px;
        background      : $grey;
    }

    .exports-box-title{
        font-size   : $fs_second_title;
        font-weight : $fw_second_title;
    }
    .exports-box-subtitle{
        font-size   : $fs_third_title;
        // font-weight : $fw_third_title;
    }

    .exports-box-param{
        display         : flex;
        align-items     : center;
        flex-direction  : row;
        margin          : 40px 10px 0 0;

        .exports-box-param-dropdown{
            display         : flex;
            flex-direction  : column;
            margin          : 0 30px;

            .exports-box-param-dropdown-label{
                font-size   : $fs_body;
                font-weight : $fw_body;
            }
        }
    }
}